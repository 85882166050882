export const FirebaseConfig = {
	"projectId": "porterbob-5a309",
	"appId": "1:679447718845:web:778f77b54de5b5585200d4",
	"databaseURL": "https://porterbob-5a309-default-rtdb.firebaseio.com",
	"storageBucket": "porterbob-5a309.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCEf7kub0-a6w7ML3yGNy7dyw-RNZsbSvM",
	"authDomain": "porterbob-5a309.firebaseapp.com",
	"messagingSenderId": "679447718845",
	"measurementId": "G-40HYSZRP0Y"
};